import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import googleads980 from '../../../assets/img/google-ads-930.jpg';
import googleads300 from '../../../assets/img/google-ads-300.png';

import Footer from '../../components/Footer/Footer';
import '../../scss/Quiz.scss';
import TotalAttempted from '../../../assets/img/total-attempted.png';
import CheckedWords from '../../../assets/img/checked-words.png';
import OopsWord from '../../../assets/img/header-incorrect.png';
import './MyProgress.scss';
import '../../scss/Home.scss';
import ProgressTab from '../../components/ProgressTab/ProgressTab';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Progresscross from '../../../assets/img/progress-cross.png';
import { Button, Container, Grid, Box, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { authen, db } from '../../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { setUserProfile } from 'shared/store/src/lib/actions/userprofile';
import { doc, getDoc } from '@firebase/firestore';
import RemoteErrorLog from '../../Utils/api';

const MyProgress = () => {
  const profile = useSelector((state: any) => state.userprofile);
  const dispatch = useDispatch();
  const [phonoTab, setPhonoTab] = useState(true);
  const [colorTab, setColorTab] = useState(false);
  const [user, setUser] = useState<any>();

  const phonogramEntries = profile?.user?.phonogram
    ? Object.entries(profile.user.phonogram)
    : [];
  phonogramEntries.sort(([keyA], [keyB]) => (keyA > keyB ? 1 : -1));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (user?.uid) {
      getUserProfile(user?.uid);
    } else if (authen?.currentUser?.uid) {
      getUserProfile(authen?.currentUser?.uid);
    }
  }, [user, authen]);

  const getBorderColor = (correct: number, total: number) => {
    if (total === 0 || authen.currentUser?.isAnonymous) {
      return 'greynish-border';
    }

    const percentage = (correct / total) * 100;
    switch (true) {
      case percentage > 90 && percentage <= 100:
        return 'green-border';
      case percentage >= 70 && percentage <= 90:
        return 'greenish-border';
      case percentage >= 50 && percentage < 70:
        return 'pinkish-border';
      case percentage >= 0 && percentage < 50:
        return 'redish-border';
    }
  };
  const getUserProfile = async (uid: string) => {
    try {
      const docRef = doc(db, 'user_profile', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        dispatch(setUserProfile(data));
        return data;
      } else {
        return null;
      }
    } catch (error) {
      RemoteErrorLog(error, 'MyProgress.tsx');

      return null;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <header>
        {' '}
        <Navbar currentPage="myprogress" />
      </header>
      <Header />

      <main className="page-bg overflow-hidden">
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
          }}
        >
          {/* <Box className="googleAds top mx-auto">
            <img src={googleads980} alt="" />
          </Box> */}
          <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
            {/* <Grid item lg={3} xs={12} sx={{ textAlign: 'center' }} className='order-item-2'> */}
            {/* <Box className="googleAds top mx-auto">
                <img src={googleads300} alt="" />
              </Box> */}
            {/* </Grid> */}
            <Grid item lg={6} xs={12}>
              <section
                className="quiz-step progress-step"
                style={{ boxShadow: 'none' }}
              >
                <Typography variant="h3">My Progress</Typography>
                <Paper
                  className="box-container"
                  sx={{ padding: '19px 14px !important' }}
                >
                  <Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <img src={profile?.user?.img_uri_https} alt="pic" />

                      <Typography
                        variant="h6"
                        fontFamily="roboto"
                        fontSize="20px"
                        fontWeight="500"
                        marginTop="4px"
                      >
                        {authen?.currentUser?.isAnonymous
                          ? 'Guest'
                          : authen.currentUser?.displayName}
                      </Typography>
                    </Box>

                    <Box className="progress-box-parent">
                      <Box className="progress-box">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '9px',
                            paddingTop: '12px',
                          }}
                        >
                          <img
                            src={TotalAttempted}
                            alt="attempted-icon"
                            width="21%"
                          />
                          <Typography
                            variant="h6"
                            fontFamily="roboto"
                            fontSize="18px"
                            fontWeight="500"
                            color="#333333"
                            lineHeight="1.2"
                            className="total-score-font"
                          >
                            Total Attempted
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          fontFamily="roboto"
                          fontSize="30px"
                          fontWeight="600"
                          color="#4FC3F7"
                          textAlign="center"
                          marginTop="12px"
                          className="score-font-para"
                        >
                          {authen?.currentUser?.isAnonymous
                            ? '--'
                            : profile.user?.metrics?.words_num_total}
                        </Typography>
                      </Box>
                      <Box className="progress-box">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '9px',
                            paddingTop: '12px',
                          }}
                        >
                          <img
                            src={CheckedWords}
                            alt="attempted-icon"
                            width="19%"
                          />
                          <Typography
                            variant="h6"
                            fontFamily="roboto"
                            fontSize="17px"
                            fontWeight="500"
                            color="#333333"
                            lineHeight="1.2"
                            className="total-score-font"
                          >
                            Yay, Correct!
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          fontFamily="roboto"
                          fontSize="30px"
                          fontWeight="600"
                          color="#2A9235"
                          textAlign="center"
                          marginTop="12px"
                          className="score-font-para"
                        >
                          {authen?.currentUser?.isAnonymous
                            ? '--'
                            : profile.user?.metrics?.words_num_correct}
                        </Typography>
                      </Box>
                      <Box className="progress-box">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '9px',
                            paddingTop: '12px',
                          }}
                        >
                          <img
                            src={OopsWord}
                            alt="attempted-icon"
                            width="18%"
                          />
                          <Typography
                            variant="h6"
                            fontFamily="roboto"
                            fontSize="18px"
                            fontWeight="500"
                            color="#333333"
                            lineHeight="1.2"
                            className="total-score-font"
                          >
                            Oops, Incorrect
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          fontFamily="roboto"
                          fontSize="30px"
                          fontWeight="600"
                          color="#EC1C24"
                          textAlign="center"
                          marginTop="12px"
                          className="score-font-para"
                        >
                          {authen?.currentUser?.isAnonymous
                            ? '--'
                            : profile.user?.metrics?.words_num_incorrect}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      className="current-level result-current-level"
                      sx={{ borderRadius: '12px', marginTop: '20px' }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          className="font-500 current-level-word"
                          sx={{
                            fontFamily: 'roboto',
                            color: '#333333',
                            fontSize: '20px',
                          }}
                        >
                          Current Level:{' '}
                          {!authen.currentUser?.isAnonymous ? (
                            <Typography
                              className=" current-level-word"
                              component={'span'}
                              style={{ fontStyle: 'italic' }}
                              sx={{
                                fontFamily: 'roboto',
                                color: '#333333',
                                fontSize: '20px',
                                fontWeight: '500',
                              }}
                            >
                              {authen?.currentUser?.isAnonymous
                                ? '--'
                                : profile.user?.metrics?.name_level_curr}
                            </Typography>
                          ) : (
                            <Typography
                              component={'span'}
                              style={{ fontStyle: 'italic' }}
                              sx={{
                                fontFamily: 'roboto',
                                color: '#333333',
                                fontSize: '20px',
                              }}
                            >
                              --
                            </Typography>
                          )}
                        </Typography>
                        <Box className="slider-current-level">
                          <Box
                            className="customprogress"
                            sx={{ width: 'unset' }}
                          >
                            {!authen.currentUser?.isAnonymous && (
                              <Typography className="custmPrgsTxtunfill">
                                {(profile.user?.metrics
                                  ?.next_level_points_scored /
                                  profile.user?.metrics
                                    ?.next_level_points_total) *
                                  100 <=
                                  70 &&
                                  `${profile.user?.metrics?.next_level_points_total} Points`}
                              </Typography>
                            )}

                            <Box
                              className="innerFill"
                              sx={{
                                width: !authen.currentUser?.isAnonymous
                                  ? `${
                                      (profile.user?.metrics
                                        ?.next_level_points_scored /
                                        profile.user?.metrics
                                          ?.next_level_points_total) *
                                        100 <=
                                      3
                                        ? 3
                                        : (profile.user?.metrics
                                            ?.next_level_points_scored /
                                            profile.user?.metrics
                                              ?.next_level_points_total) *
                                          100
                                    }%`
                                  : '3%',
                              }}
                            >
                              {!authen.currentUser?.isAnonymous && (
                                <Typography className="custmPrgsTxt">
                                  {(profile.user?.metrics
                                    ?.next_level_points_scored /
                                    profile.user?.metrics
                                      ?.next_level_points_total) *
                                    100 >=
                                    22 &&
                                    `${profile.user?.metrics?.next_level_points_scored} Points`}
                                </Typography>
                              )}
                              <Box className="customtooltip">
                                {`${
                                  authen.currentUser?.isAnonymous
                                    ? '--'
                                    : profile.user?.metrics
                                        ?.next_level_points_scored
                                } `}
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Typography
                          variant="body1"
                          className="current-level-para"
                          sx={{
                            fontFamily: 'roboto',
                            color: '#333333',
                            fontSize: '18px',
                            fontWeight: '400',
                          }}
                        >
                          You need{' '}
                          {!authen.currentUser?.isAnonymous
                            ? profile.user?.metrics?.next_level_points_remaining
                            : '--'}{' '}
                          Points more to upgrade to next level
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </section>
              <section>
                <Box className="w-640 phonogram-heading ">
                  <Button
                    className="progress-btn"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '16.56px 16.56px 0 0',
                      border: '1px solid #C0C0C0',
                      color: '#333333',
                      fontSize: '18px',
                    }}
                    variant="outlined"
                    onClick={() => {
                      setPhonoTab(false);
                      setColorTab(true);
                    }}
                    startIcon={<InfoOutlinedIcon />}
                  >
                    Phonogram Performance Report
                  </Button>
                </Box>

                {phonoTab && (
                  <section className="performance-progress">
                    <Box className="progress-border-section">
                      <Box className="search-word progress-word">
                        {phonogramEntries.map(([key, value]: any, index) => (
                          <Box
                            sx={{
                              padding: 1,
                              textAlign: 'center',
                              boxShadow: 'none',
                            }}
                          >
                            <Box key={index}>
                              <Typography
                                variant="body1"
                                component="div"
                                className={`progress-inside-search font-24 font-600 ${getBorderColor(
                                  value.correct,
                                  value.total
                                )} progress-inside-box`}
                              >
                                <span className="progress-border-value">
                                  {' '}
                                  {key}
                                </span>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  className="font-16 progress-colorful-value"
                                >
                                  {!authen.currentUser?.isAnonymous ? (
                                    <>
                                      {' '}
                                      <span className="green">
                                        {' '}
                                        {value.correct}
                                      </span>
                                      /
                                      <span className="sky-blue">
                                        {value.total}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="green">-</span>/
                                      <span className="sky-blue">-</span>
                                    </>
                                  )}
                                </Typography>
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </section>
                )}

                {colorTab && (
                  <section
                    className="performance-progress"
                    style={{ position: 'relative' }}
                  >
                    <img
                      src={Progresscross}
                      alt="pic-cross"
                      className="cursor-pointer"
                      onClick={() => {
                        setPhonoTab(true);
                        setColorTab(false);
                      }}
                    />
                    <Box className="progress-border-section">
                      <Typography
                        className="progress-heading-top"
                        variant="body1"
                        sx={{
                          fontFamily: 'Mulish',
                          color: '#333333',
                          fontSize: '20px',
                          fontWeight: '500',
                          paddingBottom: '30px',
                        }}
                      >
                        These consist of combinations of letters (e.g., “ch”)
                        that represent distinct sounds in spoken words.{' '}
                        <strong>Example:</strong>
                        “ch” represents the sound heard in words like “chat”.
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '52px',
                            alignItems: 'center',
                          }}
                          className="progress-text-detail gap-progress"
                        >
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            className="font-24 progress-colorful-value"
                          >
                            <span className="green">9</span>/
                            <span className="sky-blue">10</span>
                          </Typography>
                          <Box>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '600',
                                textDecoration: 'underline',
                              }}
                            >
                              Correct Answers:
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '500',
                              }}
                            >
                              9 correct out of 10 in words containing these
                              letters.
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '14px',
                          }}
                          className="progress-text-detail"
                        >
                          <Box className=" progress-word ">
                            <Box
                              sx={{
                                textAlign: 'center',
                                boxShadow: 'none',
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="body1"
                                  component="div"
                                  className="box-inside-search font-24 font-600 green-border progress-inside-box progress-detail "
                                >
                                  <span className="progress-border-value font-20">
                                    {' '}
                                    dga
                                  </span>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    className="font-16 progress-colorful-value"
                                  >
                                    <span className="green">10</span>/
                                    <span className="sky-blue">10</span>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '600',
                                textDecoration: 'underline',
                              }}
                            >
                              Greater than 90% Correct:
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '500',
                              }}
                            >
                              Excellent work! Proficiency achieved.
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '14px',
                          }}
                          className="progress-text-detail"
                        >
                          <Box className=" progress-word ">
                            <Box
                              sx={{
                                textAlign: 'center',
                                boxShadow: 'none',
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="body1"
                                  component="div"
                                  className="box-inside-search font-24 font-600 greenish-border progress-inside-box progress-detail "
                                >
                                  <span className="progress-border-value font-20">
                                    {' '}
                                    ear
                                  </span>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    className="font-16 progress-colorful-value"
                                  >
                                    <span className="green">7</span>/
                                    <span className="sky-blue">10</span>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '600',
                                textDecoration: 'underline',
                              }}
                            >
                              70% to 90% Correct:
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '500',
                              }}
                            >
                              Good effort! Minor adjustments can enhance
                              proficiency.
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '14px',
                          }}
                          className="progress-text-detail"
                        >
                          <Box className=" progress-word ">
                            <Box
                              sx={{
                                textAlign: 'center',
                                boxShadow: 'none',
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="body1"
                                  component="div"
                                  className="box-inside-search font-24 font-600 redish-border progress-inside-box progress-detail "
                                  style={{ borderColor: '#EE7575' }}
                                >
                                  <span className="progress-border-value font-20">
                                    {' '}
                                    aw
                                  </span>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    className="font-16 progress-colorful-value"
                                  >
                                    <span className="green">5</span>/
                                    <span className="sky-blue">10</span>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '600',
                                textDecoration: 'underline',
                              }}
                            >
                              50% to 70% Correct:
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '500',
                              }}
                            >
                              Some improvement required. Keep refining your
                              skills.
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '14px',
                          }}
                          className="progress-text-detail"
                        >
                          <Box className=" progress-word ">
                            <Box
                              sx={{
                                textAlign: 'center',
                                boxShadow: 'none',
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="body1"
                                  component="div"
                                  className="box-inside-search font-24 font-600 redish-border  progress-inside-box progress-detail "
                                >
                                  <span className="progress-border-value font-20">
                                    {' '}
                                    ai
                                  </span>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    className="font-16 progress-colorful-value"
                                  >
                                    <span className="green">3</span>/
                                    <span className="sky-blue">10</span>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '600',
                                textDecoration: 'underline',
                              }}
                            >
                              Less than 50% Correct:
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '500',
                              }}
                            >
                              Significant improvement needed. Keep practicing.
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '14px',
                          }}
                          className="progress-text-detail"
                        >
                          <Box className=" progress-word ">
                            <Box
                              sx={{
                                textAlign: 'center',
                                boxShadow: 'none',
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="body1"
                                  component="div"
                                  className="box-inside-search font-24 font-600  greynish-border progress-inside-box progress-detail "
                                >
                                  <span className="progress-border-value font-20">
                                    {' '}
                                    ch
                                  </span>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    className="font-16 progress-colorful-value"
                                  >
                                    <span className="green">0</span>/
                                    <span className="sky-blue">0</span>
                                  </Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '600',
                                textDecoration: 'underline',
                              }}
                            >
                              Not Attempted:
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'Mulish',
                                color: '#333333',
                                fontSize: '18px',
                                fontWeight: '500',
                              }}
                            >
                              These letters have not been attempted yet. Please
                              give it a try.
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </section>
                )}
              </section>
              <section className="tab-profile-game progress-tab-game">
                <Box className="w-640">
                  <ProgressTab />
                </Box>
              </section>
            </Grid>
            {/* <Grid
              item
              lg={3}
              xs={12}
              className="pb-3"
              sx={{ textAlign: 'center' }}
            > */}
            {/* <Box className="googleAds top mx-auto">
                <img src={googleads300} alt="" />
              </Box> */}
            {/* </Grid> */}
          </Grid>
        </Container>
      </main>
      <Footer />
    </Box>
  );
};

export default MyProgress;
