import React, { useEffect, useState } from 'react';

import '../../scss/Form.scss';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import mailicon from '../../../assets/img/signup-icon.svg';
import googleicon from '../../../assets/img/google-icon.svg';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  GoogleAuthProvider,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { authen, provider } from '../../firebase/firebase';
import validator from 'validator';
import { fetchCookieUsers } from '../../Utils/firebase-services';
import cookiePreferences from 'shared/Utils/CookiePreferenceApi/api';
import RemoteErrorLog from '../../Utils/api';
import { environment } from '../../../environments/environment';
import CustomModal from '../../components/CustomModal';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import { Helmet } from 'react-helmet';

export default function LogIn() {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [rememberMe, setRememberMe] = useState(false);
  const [enable, setEnable] = useState<boolean>(false);
  const [enable1, setEnable1] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isQuestionModalVisible, setQuestionModalVisible] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const [firebaseUser, setFirebaseUser] = useState<any>(null);
  const [firebaseUserProvider, setFirebaseUserProvider] = useState<string>('');
  const [isCookieModalVisible, setIsCookieModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const [isCookiePolicyModalVisible, setIsCookiePolicyModalVisible] =
  //   useState(false);

  const loadCredentials = async () => {
    const storedCredentials = await localStorage.getItem('userCredentials');
    if (storedCredentials) {
      setRememberMe(true);
      setEnable(true);
      setEnable1(true);
      const { email, password } = JSON.parse(storedCredentials);
      setEmail(email);
      setPassword(password);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const signInAnonymouslyy = async () => {
    setIsLoading(true);
    signInAnonymously(authen)
      .then(async (userCredential) => {
        const { uid, isAnonymous, displayName } = userCredential.user;

        fetchCookieUsers(uid).then((abc) => {
          setFirebaseUser({ uid, isAnonymous, displayName });
          localStorage.setItem('userData', JSON.stringify(userCredential.user));
        });
      })
      .catch((error) => {
        RemoteErrorLog(error, 'Login.tsx');
      });
  };

  const userLogin = async () => {
    setIsLoading(true);
    try {
      const storedCredentials = await localStorage.getItem('userCredentials');
      if (storedCredentials && !rememberMe) {
        await localStorage.removeItem('userCredentials');
      }

      const userCredential = await signInWithEmailAndPassword(
        authen,
        email,
        password
      );
      const user = userCredential.user;
      const idToken = await userCredential.user.getIdToken();
      if (rememberMe) {
        await localStorage.setItem(
          'userCredentials',
          JSON.stringify({ email, password })
        );
      }
      if (user.emailVerified) {
        localStorage.setItem('userData', JSON.stringify(userCredential.user));
        const userCookiePreference = await fetchCookieUsers(user.uid);
        const { uid, isAnonymous, displayName } = user;
        if (userCookiePreference == 'Error') {
          setQuestionModalVisible(true);
          setApiErrorMessage(
            'We’re currently experiencing some issues. Please try again later.'
          );
          setIsLoading(false);
          localStorage.removeItem('userData');
        } else if (userCookiePreference == false) {
          setFirebaseUser({ uid, isAnonymous, displayName });
        } else {
          setIsLoading(false);
          navigate('/home');
          setIsLoading(false);
        }
      } else {
        setIsModalVisible(true);

        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);

      RemoteErrorLog(error, 'Login.tsx');
      window.alert(
        'The email or password entered is incorrect. Please try again.'
      );
    }
  };

  const validateEmail = (input: string) => {
    setEmail(input);
    if (!input) {
      setErrorStatus('Email is required.');
      setEnable(false);
      return;
    }
    if (validator.isEmail(input)) {
      setEnable(true);
      setErrorStatus('');
    } else {
      setEnable(false);
      setErrorStatus('Please enter a valid email address.');
    }
  };

  const validatePassword = (text: string) => {
    if (text.length < 6) {
      setErrorMessage('The password must be at least 6 characters long.');
      setEnable1(false);
    } else {
      setEnable1(true);
      setErrorMessage('');
    }
  };
  const googleSignIn = async () => {
    try {
      const result = await signInWithPopup(authen, provider);
      const googleCredentials = GoogleAuthProvider.credentialFromResult(result);

      const userInfo = signInWithCredential(authen, googleCredentials!);

      setIsLoading(true);
      localStorage.setItem('userData', JSON.stringify((await userInfo).user));
      const { uid, isAnonymous, displayName } = (await userInfo).user;
      const userCookiePrefernce = await fetchCookieUsers(uid);
      if (userCookiePrefernce == 'Error') {
        setQuestionModalVisible(true);
        setApiErrorMessage(
          'We’re currently experiencing some issues. Please try again later.'
        );
        setIsLoading(false);
        localStorage.removeItem('userData');
      } else if (userCookiePrefernce == false) {
        setFirebaseUser({ uid, isAnonymous, displayName });
      } else {
        setIsLoading(false);
        navigate('/home');
      }
      setFirebaseUserProvider((await userInfo).user.providerData[0].providerId);
    } catch (error: any) {
      RemoteErrorLog(error, 'Login.tsx');
    }
  };
  useEffect(() => {
    loadCredentials();
  }, []);

  useEffect(() => {
    if (firebaseUser) {
      // cookieModalOpen();
      hideCookieModal();
    }
  }, [firebaseUser]);

  const cookieModalOpen = () => {
    setIsLoading(false);
    setIsCookieModalVisible(!isCookieModalVisible);
  };
  const hideModal = () => {
    setIsModalVisible(false);
    setQuestionModalVisible(false);
  };

  const cookieApiCall = async (cookieInfo: any) => {
    const token = await (authen?.currentUser as any).getIdToken(true);
    const result = await cookiePreferences(
      cookieInfo,
      token,
      environment?.apiUrl
    );
    if (result == 'Error') {
      setQuestionModalVisible(true);
      setApiErrorMessage(
        'We’re currently experiencing some issues. Please try again later.'
      );
      setIsLoading(false);
      localStorage.removeItem('userData');
    } else {
      setIsLoading(false);
      navigate('/home');
    }
  };

  const hideCookieModal = async () => {
    setIsCookieModalVisible(false);
    const cookieInfo = {
      uid: firebaseUser.uid,
      full_name: firebaseUser.displayName,
      is_anonymous: firebaseUser.isAnonymous,
    };
    if (cookieInfo.uid) {
      setIsLoading(true);
      cookieApiCall(cookieInfo);
    }
  };
  // const openCookiePolicyModal = () => {
  //   setIsCookieModalVisible(false);
  //   setIsCookiePolicyModalVisible(true);
  // };

  // const onSavePreference = () => {
  //   setIsCookiePolicyModalVisible(false);

  //   navigate('/home');
  // };
  return (
    <>
      <Helmet>
        <title>
          Login to Our Free Online Spelling Test Game - Spelling Hero
        </title>
        <meta
          name="description"
          content="Login to Spelling Hero app, an online spelling test app to practice spelling words with fun activities by playing games to improve your spelling skills. Login now!"
        />
        <link rel="canonical" href="https://spellinghero.app/login"></link>
      </Helmet>
      <header>
        <Navbar currentPage="login" />
      </header>
      <main className="main-container">
        <section className="quiz-signup">
          {/* <CookiesModal
            firebaseUserProvider={firebaseUserProvider}
            firebaseUser={firebaseUser}
            // navigation={navigation}
            isModalVisible={isCookieModalVisible}
            onAccept={hideCookieModal}
            onManage={openCookiePolicyModal}
          />

          <CookiePolicyModal
            firebaseUserProvider={firebaseUserProvider}
            firebaseUser={firebaseUser}
            // navigation={navigation}
            isModalVisible={isCookiePolicyModalVisible}
            onAccept={hideCookieModal}
            onSavePreference={onSavePreference}
            handleClose={() => setIsCookiePolicyModalVisible(false)}
          /> */}
          <Box sx={{ padding: 0 }}>
            <Grid container spacing={2}>
              <Grid
                item
                lg={5}
                xs={12}
                sm={12}
                sx={{ paddingLeft: 0 }}
                className="sidebar-top-spacing"
              >
                <Sidebar />
              </Grid>
              <Grid item xs={12} sm={12} lg={7} xl={6}>
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100vh',
                    }}
                  >
                    {' '}
                    <CircularProgress
                      size={56}
                      sx={{
                        color: 'primary.main',
                      }}
                    />{' '}
                  </Box>
                ) : (
                  <Box
                    className="form-container"
                    sx={{ paddingLeft: { lg: 3, xl: 5 } }}
                  >
                    <Box>
                      <Typography variant="h4" className="mb-5 font-600">
                        Log in
                      </Typography>
                      <form>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          className="mb-3 signup-flex-basis-remove"
                        >
                          <Grid item xs={12} md={4} sx={{ flexBasis: '33%' }}>
                            <Typography
                              variant="body1"
                              component="label"
                              className="form-label mb-md-0"
                            >
                              Your email or username*
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={8} sx={{ flexBasis: '67%' }}>
                            <TextField
                              fullWidth
                              value={email}
                              placeholder="example@email.com"
                              variant="outlined"
                              onChange={(e) => validateEmail(e.target.value)}
                            />
                            {errorStatus && (
                              <p style={{ color: 'red' }}>{errorStatus}</p>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          className="mb-2 signup-flex-basis-remove"
                        >
                          <Grid item xs={12} md={4} sx={{ flexBasis: '33%' }}>
                            <Typography
                              variant="body1"
                              component="label"
                              className="form-label mb-md-0"
                            >
                              Password*
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={8} sx={{ flexBasis: '67%' }}>
                            <TextField
                              type="password"
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                validatePassword(e.target.value);
                              }}
                              fullWidth
                              placeholder="Please enter at least 6 characters."
                              variant="outlined"
                            />
                            {errorMessage ? (
                              <p style={{ color: 'red' }}>{errorMessage}</p>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          className="mb-4"
                        >
                          <Grid item xs={12} md={8} sx={{ ml: { md: 'auto' } }}>
                            <Box
                              className="d-flex justify-content-between mb-3 signup-flex-basis-remove font-14"
                              sx={{ alignItems: 'center' }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={rememberMe}
                                    onChange={(e) =>
                                      setRememberMe(e.target.checked)
                                    }
                                  />
                                }
                                label="Remember Me"
                              />
                              <Link to="/forgot-password">
                                Forgot Password?
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={12} md={10} sx={{ margin: 'auto' }}>
                            <Button
                              variant="contained"
                              sx={{ textTransform: 'none' }}
                              className="btn btn-primary"
                              fullWidth
                              disabled={!(enable === true && enable1 === true)}
                              onClick={() => userLogin()}
                            >
                              Log in
                            </Button>
                            <Box className="seperator">
                              <span>OR</span>
                            </Box>
                            <Button
                              variant="outlined"
                              sx={{ textTransform: 'none' }}
                              className="btn btn-member btn-outline-secondary "
                              onClick={googleSignIn}
                              fullWidth
                              startIcon={
                                <img
                                  src={googleicon}
                                  alt="google icon"
                                  className="icon-offset"
                                />
                              }
                            >
                              Login with Google
                            </Button>
                            <Link
                              to="/signup"
                              style={{ textDecoration: 'none' }}
                            >
                              <Button
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                                fullWidth
                                startIcon={
                                  <img
                                    src={mailicon}
                                    alt="Mail Icon"
                                    className="icon-offset"
                                  />
                                }
                                className="mt-3 btn btn-primary"
                              >
                                New user? Sign up for free
                              </Button>
                            </Link>

                            <Typography
                              variant="body2"
                              align="center"
                              sx={{ textTransform: 'none' }}
                              className="mt-3 font-600 cursor-pointer"
                            >
                              Explore as{' '}
                              <Link to="">
                                <span onClick={signInAnonymouslyy}>Guest</span>
                              </Link>
                            </Typography>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </section>

        <CustomModal
          isModalVisible={isModalVisible}
          onPress={hideModal}
          buttonText="OK"
          modalTitle="Verify your Email ID"
          text="Before login, please verify your email address. Click the verification link we’ve sent to your inbox!"
          icon={true}
        />
        <CustomNoResponseModal
          isModalVisible={isQuestionModalVisible}
          onPress={hideModal}
          buttonText="OK"
          modalTitle="Oops!"
          text={apiErrorMessage}
        />
      </main>
      <Footer />
    </>
  );
}
