import axios from "axios";
import { environment } from "../../environments/environment";




const RemoteErrorLog = async (error: any ,filename='') => {
  const userAgent = navigator.userAgent;


    const errorpara = {
        service_name: 'website-spellinghero',
        service_url: environment.baseUrl,
        user_agent: userAgent,
        stacktrace: error?.stack ,
        error_code: error?.response?.status ?error?.response?.status :1000,
        filename:filename!=="" ? filename :null,
        ui_error_code: error?.code ? error?.code  :null,
        error_message: error?.message ? error?.message :null,
      };
       try {
        const response = await axios.post(
          'https://ui-interaction-2pkagc3btq-nn.a.run.app/logRemoteError',
          errorpara,
          {
            method: 'POST',
            headers: {
              accept: 'application/json',
            },
          },
        );
        
      } catch (error) {
      }
  };
  export default RemoteErrorLog;