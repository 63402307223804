import React, { useEffect, useRef, useState } from 'react';

import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import mailicon from '../../../assets/img/email-icon.svg';
import googleicon from '../../../assets/img/google-icon.svg';
import '../../scss/Form.scss';
import Select from 'react-select';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  calenderYears,
  daysOfMonth,
  monthsOfYear,
} from '../../constants/DropDownConstants';
import validator from 'validator';
import { isValidDate } from '../../../../../../shared/functions/signUp';
import { calculateAge } from '../../../../../../shared/functions/signUp';
import sendCoppaEmail from '../../../../../../shared/Utils/AutenticationApi/sendEmail';
import cookiePreferences from '../../../../../../shared/Utils/CookiePreferenceApi/api';
import { environment } from '../../../environments/environment';

import {
  EmailAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  linkWithCredential,
  sendEmailVerification,
  signInWithCredential,
  updateProfile,
  signInWithPopup,
  onAuthStateChanged,
  linkWithPopup,
} from 'firebase/auth';
import { firebaseUserType } from '../../types/types';
import { authen, provider } from '../../firebase/firebase';
import { fetchCookieUsers } from '../../Utils/firebase-services';
import CookiesModal from '../../components/Cookies/CookiesModal';
import CookiePolicyModal from '../../components/Cookies/CookiePolicyModal';
import AlertPopup from '../../components/Alert/AlertPopup';
import CustomModal from '../../components/CustomModal';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import RemoteErrorLog from '../../Utils/api';
import { Helmet } from 'react-helmet';
import updateUserProfile from 'shared/Utils/UpdateProfileApi/Api';

export default function SignUp() {
  const { state } = useLocation();

  const [email, setEmail] = useState<string>('');
  const [createPassword, setCreatePassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [month, setMonth] = useState<any>(0);
  const [day, setDay] = useState<any>(0);
  const [year, setYear] = useState<any>(0);
  const [userDob, setUserDob] = useState<string>('');

  const [validateDate, setValidateDate] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [age, setAge] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isCookieModalVisible, setIsCookieModalVisible] = useState(false);
  // const [isCookiePolicyModalVisible, setIsCookiePolicyModalVisible] =
  //   useState(false);

  const [errorStatus, setErrorStatus] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [AgeEnable, setAgeEnable] = useState(false);

  const [emailEnable, setEmailEnable] = useState(false);
  const [firstEnable, setFirstEnable] = useState(false);
  const [lastEnable, setLastEnable] = useState(false);
  const [passEnable, setPassEnable] = useState(false);
  //   const [firebaseUser, setFirebaseUser] = useState<firebaseUserType>();
  const [firebaseUser, setFirebaseUser] = useState<firebaseUserType>();
  const [firebaseUserProvider, setFirebaseUserProvider] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [dayValue, setDayValue] = useState<any>(null);
  const [monthValue, setMonthValue] = useState<any>(null);
  const [yearValue, setYearValue] = useState<any>(null);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const convertToDateString = (
    month: { label: string; value: number },
    day: { label: string; value: number },
    year: { label: string; value: number }
  ): string => {
    const yearValue = year?.value;
    const monthValue = month?.value?.toString().padStart(2, '0');
    const dayValue = day?.value?.toString().padStart(2, '0');
    return `${yearValue}-${monthValue}-${dayValue}`;
  };

  useEffect(() => {
    if (!validateDate) {
      const dob = convertToDateString(month, day, year);
      setUserDob(dob);
    }
  }, [validateDate]);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (state?.createPassword) {
      setDayValue(state?.day.value);
      setMonthValue(state?.month.value);
      setYearValue(state?.year.value);
      setIsChecked(state?.isChecked);
      handleDateChange(state?.month, state?.day, state?.year);
      handleFirstName(state?.firstName);
      handleLastName(state?.lastName);
      setCreatePassword(state?.createPassword);
      validatePassword(state?.createPassword);
    }
  }, [state]);

  const validateEmail = (input: string) => {
    setEmail(input);
    if (!input) {
      setErrorStatus('Email is required.');
      setEmailEnable(false);
      return;
    }
    if (validator.isEmail(input)) {
      setEmailEnable(true);
      setErrorStatus('');
    } else {
      setEmailEnable(false);
      setErrorStatus('Please enter a valid email address.');
    }
  };
  const validatePassword = (text: string) => {
    if (text.length < 6) {
      setErrorMessage('The password must be at least 6 characters long.');
      setPassEnable(false);
    } else {
      setPassEnable(true);
      setErrorMessage('');
    }
  };

  const handleDateChange = (
    selectedMonth: any,
    selectedDay: any,
    selectedYear: any
  ) => {
    if (
      isValidDate(selectedMonth.value, selectedDay.value, selectedYear.value)
    ) {
      setValidateDate(false);
      const agee = calculateAge(
        selectedMonth.value,
        selectedDay.value,
        selectedYear.value
      );
      if (agee >= 0) {
        setAge(agee);
        setAgeEnable(true);
      } else {
        setValidateDate(true);
        setAgeEnable(false);
      }
    } else {
      setValidateDate(true);
    }
    setMonth(selectedMonth);
    setDay(selectedDay);
    setYear(selectedYear);
  };

  const handleFirstName = (inputText: string) => {
    setFirstName(inputText);
    if (!inputText.trim()) {
      setFirstEnable(false);
    } else {
      setFirstEnable(true);
    }
  };
  const handleLastName = (inputText1: string) => {
    setLastName(inputText1);
    if (!inputText1.trim()) {
      setLastEnable(false);
    } else {
      setLastEnable(true);
    }
  };

  // const cookieModalOpen = () => {
  //   setIsLoading(false);
  //   setIsCookieModalVisible(!isCookieModalVisible);
  //   //   hideCookieModal();
  // };

  const hideNoQuestionModal = () => {
    setIsQuestionModalVisible(false);
  };

  useEffect(() => {
    if (firebaseUser) {
      // cookieModalOpen();
      hideCookieModal();
    }
  }, [firebaseUser]);

  const cookieApiCall = async (cookieInfo: any) => {
    const token = await (authen?.currentUser as any).getIdToken(true);

    const result = await cookiePreferences(
      cookieInfo,
      token,
      environment?.apiUrl
    );

    return result;
  };
  // const openCookiePolicyModal = () => {
  //   setIsCookieModalVisible(false);
  //   setIsCookiePolicyModalVisible(true);
  // };

  // const onSavePreference = () => {
  //   setIsCookiePolicyModalVisible(false);
  //   if (firebaseUserProvider !== 'google.com') {

  //     if (age > 13.002) {
  //       setIsModalVisible(true);
  //     } else {
  //       navigate('/email-verify', {
  //         state: {
  //           email: email,
  //           isChecked: isChecked,
  //           firstName: firstName,
  //           lastName: lastName,
  //           uid: firebaseUser?.uid,
  //           createPassword: createPassword,
  //           month: month,
  //           day: day,
  //           year: year,
  //         },
  //       });
  //       // navigate('VerifyEmailScreen', {
  //       //   email,
  //       //   createPassword,
  //       //   firstName,
  //       //   lastName,
  //       // });
  //     }
  //   } else {
  //     navigate('/home');
  //   }
  // };

  const hideCookieModal = async () => {
    // setIsCookieModalVisible(false);
    // setIsCookiePolicyModalVisible(false);
    const dob = convertToDateString(month, day, year);
    const cookieInfo = {
      uid: firebaseUser?.uid,
      full_name: firebaseUser?.displayName,
      is_anonymous: firebaseUser?.isAnonymous,
      dob: dob,
    };
    let apiResult: any;
    if (cookieInfo.uid) {
      apiResult = await cookieApiCall(cookieInfo);
    }
    if (firebaseUserProvider !== 'google.com') {
      if (age > 13.002) {
        if (apiResult == 'Error') {
          setIsQuestionModalVisible(true);
          setApiErrorMessage(
            'We’re currently experiencing some issues. Please try again later.'
          );
          setIsLoading(false);
        } else {
          setIsModalVisible(true);
          setIsLoading(false);
        }

        setIsLoading(false);
      } else {
        if (apiResult == 'Error') {
          setIsQuestionModalVisible(true);
          setApiErrorMessage(
            'We’re currently experiencing some issues. Please try again later.'
          );
          setIsLoading(false);
        } else {
          navigate('/email-verify', {
            state: {
              email: email,
              isChecked: isChecked,
              firstName: firstName,
              lastName: lastName,
              uid: firebaseUser?.uid,
              createPassword: createPassword,
              month: month,
              day: day,
              year: year,
            },
          });
        }
      }
    } else {
      if (apiResult == 'Error') {
        setIsQuestionModalVisible(true);
        setApiErrorMessage(
          'We’re currently experiencing some issues. Please try again later.'
        );
        setIsLoading(false);
        localStorage.removeItem('userData');
      } else {
        navigate('/home');
      }
    }
  };

  const upgradeToEmailPassword = async (email: string, password: string) => {
    try {
      const credential = EmailAuthProvider.credential(email, password);
      linkWithCredential(authen.currentUser!, credential)
        .then((usercred) => {
          if (usercred.user) {
            updateProfile(usercred.user, {
              displayName: `${firstName} ${lastName}`,
            })
              .then(async () => {
                const updateProfileType = {
                  uid: authen?.currentUser?.uid ? authen?.currentUser?.uid : '',
                  full_name: `${firstName} ${lastName}`,
                };
                await updateUserProfile(updateProfileType, environment?.apiUrl);
              })
              .then(async () => {
                if (age > 13.002) {
                  await sendEmailVerification(usercred.user);
                  const { uid, isAnonymous, displayName } = usercred.user;
                  const userCookiePrefernce = await fetchCookieUsers(uid);
                  // if (userCookiePrefernce !== true) {
                  //   setFirebaseUser({ uid, isAnonymous, displayName } as any);
                  // } else {
                  if (userCookiePrefernce === 'Error') {
                    setIsQuestionModalVisible(true);
                    setApiErrorMessage(
                      'We’re currently experiencing some issues. Please try again later.'
                    );
                    setIsLoading(false);
                  } else if (userCookiePrefernce === false) {
                    setFirebaseUser({ uid, isAnonymous, displayName } as any);
                  } else {
                    setIsLoading(false);
                    setIsModalVisible(!isModalVisible);
                  }
                  // setIsLoading(false);
                  // setIsModalVisible(true);
                  // }
                } else {
                  const { uid, isAnonymous, displayName } = usercred.user;
                  const userInfo = {
                    email_type: 'coppa',
                    recipient_email: `${email}`,
                    recipient_full_name: `${firstName} ${lastName}`,
                    is_fb_deep_link: false,
                    continue_url: `${environment?.baseUrl}verify-user/${uid}`,
                  };
                  const token = await (authen?.currentUser as any).getIdToken(
                    true
                  );

                  const result = await sendCoppaEmail(
                    userInfo,
                    token,
                    environment?.apiUrl
                  );
                  if (result == 'Error') {
                    setIsQuestionModalVisible(true);
                    setApiErrorMessage(
                      'We’re currently experiencing some issues. Please try again later.'
                    );
                    setIsLoading(false);
                  } else if (!result?.error_message) {
                    const { uid, isAnonymous, displayName } = usercred.user;
                    const userCookiePrefernce = await fetchCookieUsers(uid);
                    if (userCookiePrefernce !== true) {
                      setFirebaseUser({ uid, isAnonymous, displayName } as any);
                    } else {
                      setIsLoading(false);

                      navigate('/email-verify', {
                        state: {
                          email: email,
                          isChecked: isChecked,
                          firstName: firstName,
                          lastName: lastName,
                          uid: uid,
                          createPassword: createPassword,
                          month: month,
                          day: day,
                          year: year,
                        },
                      });
                    }
                  } else {
                    setApiErrorMessage(result?.error_message);
                    setIsQuestionModalVisible(true);
                    setIsLoading(false);
                  }
                }
              })
              .catch((error) => {
                RemoteErrorLog(error, 'SignUp.tsx');
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setAlertSeverity('error');
          setAlertMessage(
            'Oops! There’s already an account linked to this email address. Please try signing up with a different email ID.'
          );
          setAlertOpen(true);
          RemoteErrorLog(error, 'SignUp.tsx');
        });
    } catch (error) {
      if (error === 'auth/email-already-in-use') {
        setAlertSeverity('error');
        setAlertMessage('Email address is already in use!');
        setAlertOpen(true);
      }
      RemoteErrorLog(error, 'SignUp.tsx');
    }
  };

  const handleSignUpClick = (type = '') => {
    try {
      if (window.grecaptcha) {
        window.grecaptcha.enterprise.ready(async () => {
          const token = await window.grecaptcha.enterprise.execute(
            environment?.recaptchaSiteKey,
            { action: 'SIGNUP' }
          );

          // Verify the token using the Google API
          const verificationUrl = `https://recaptchaenterprise.googleapis.com/v1/projects/${environment?.firebase?.projectId}/assessments?key=${environment?.firebase?.apiKey}`;

          const response = await fetch(verificationUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              event: {
                token: token,
                siteKey: environment?.recaptchaSiteKey,
                expectedAction: 'SIGNUP',
              },
            }),
          });

          const result = await response.json();

          if (result?.tokenProperties?.valid) {
            if (type === 'google') {
              signInWithGoogle();
            } else {
              signUpNewUser();
            }
          } else {
            alert(
              'reCAPTCHA verification failed: ' +
                result?.tokenProperties?.invalidReason
            );
          }
        });
      }
    } catch (error) {
      alert('Failed to verify reCAPTCHA. Please try again later.');
      RemoteErrorLog(error, 'SignUp.tsx');
    }
  };
  const signUpNewUser = async () => {
    setIsLoading(true);

    if (user?.uid && user?.isAnonymous) {
      upgradeToEmailPassword(email, createPassword);
    } else {
      try {
        createUserWithEmailAndPassword(authen, email, createPassword)
          .then((userCredentials) => {
            if (userCredentials.user) {
              updateProfile(userCredentials.user, {
                displayName: `${firstName} ${lastName}`,
              })
                .then(async () => {
                  if (age > 13.002) {
                    await sendEmailVerification(userCredentials.user);
                    const { uid, isAnonymous, displayName } =
                      userCredentials.user;
                    setFirebaseUser({ uid, isAnonymous, displayName } as any);
                  } else {
                    const { uid, isAnonymous, displayName } =
                      userCredentials.user;

                    const userInfo = {
                      email_type: 'coppa',
                      recipient_email: `${email}`,
                      recipient_full_name: `${firstName} ${lastName}`,
                      is_fb_deep_link: false,
                      continue_url: `${environment?.baseUrl}verify-user/${uid}`,
                    };
                    const token = await (authen?.currentUser as any).getIdToken(
                      true
                    );
                    const result = await sendCoppaEmail(
                      userInfo,
                      token,
                      environment?.apiUrl
                    );
                    if (result == 'Error') {
                      setIsQuestionModalVisible(true);
                      setApiErrorMessage(
                        'We’re currently experiencing some issues. Please try again later.'
                      );
                      setIsLoading(false);
                    } else if (!result?.error_message) {
                      const { uid, isAnonymous, displayName } =
                        userCredentials.user;
                      setFirebaseUser({ uid, isAnonymous, displayName } as any);
                    } else {
                      setApiErrorMessage(result?.error_message);
                      setIsQuestionModalVisible(true);
                    }
                  }
                })
                .catch((error) => {
                  RemoteErrorLog(error, 'SignUp.tsx');
                });
            }
          })
          .catch((error) => {
            setIsLoading(false);

            setAlertSeverity('error');
            setAlertMessage(
              'Oops! There’s already an account linked to this email address. Please try signing up with a different email ID.'
            );
            setAlertOpen(true);
            RemoteErrorLog(error, 'SignUp.tsx');
          });
      } catch (error) {
        if (error === 'auth/email-already-in-use') {
          setAlertSeverity('error');
          setAlertMessage('Email address is already in use!');
          setAlertOpen(true);
        }
        RemoteErrorLog(error, 'SignUp.tsx');
      }
    }
  };

  const signInWithGoogle = async () => {
    if (user?.uid && user?.isAnonymous) {
      try {
        const result = await linkWithPopup(user, provider);

        setIsLoading(true);

        await updateProfile(result.user, {
          displayName: `${result.user.providerData[0].displayName} `,
        });
        await user.reload();

        // Get the updated user profile
        const updatedUser = authen.currentUser;
        const updateProfileType = {
          uid: authen?.currentUser?.uid ? authen?.currentUser?.uid : '',
          full_name: `${result.user.providerData[0].displayName}`,
        };
        await updateUserProfile(updateProfileType, environment?.apiUrl);
        localStorage.setItem('userData', JSON.stringify(updatedUser));
        const { uid, isAnonymous, displayName } = updatedUser!;
        const userCookiePrefernce = await fetchCookieUsers(uid);
        if (userCookiePrefernce !== true) {
          setFirebaseUser({ uid, isAnonymous, displayName } as any);
        } else {
          setIsLoading(false);

          navigate('/home');
        }
        setFirebaseUserProvider((await result).user.providerData[0].providerId);
      } catch (error: any) {
        if (error?.code === 'auth/credential-already-in-use') {
          // window.alert('Email already exists');
          setAlertSeverity('error');
          setAlertMessage('Email address is already in use!');
          setAlertOpen(true);
        }
        RemoteErrorLog(error, 'SignUp.tsx');
      }
    } else {
      try {
        const result = await signInWithPopup(authen, provider);
        const googleCredentials =
          GoogleAuthProvider.credentialFromResult(result);

        const userInfo = signInWithCredential(authen, googleCredentials!);
        setIsLoading(true);
        localStorage.setItem('userData', JSON.stringify((await userInfo).user));
        const { uid, isAnonymous, displayName } = (await userInfo).user;
        const userCookiePrefernce = await fetchCookieUsers(uid);
        if (userCookiePrefernce !== true) {
          setFirebaseUser({ uid, isAnonymous, displayName } as any);
        } else {
          setIsLoading(false);
          navigate('/home');
        }
        setFirebaseUserProvider(
          (await userInfo).user.providerData[0].providerId
        );
      } catch (error) {
        RemoteErrorLog(error, 'SignUp.tsx');
      }
    }
  };
  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const hideModal = () => {
    setIsModalVisible(!isModalVisible);
    navigate('/login');
  };

  const loadReCaptchaScript = (siteKey: string) => {
    return new Promise<void>((resolve, reject) => {
      if (
        document.querySelector(
          `script[src="https://www.google.com/recaptcha/enterprise.js?render=${siteKey}"]`
        )
      ) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () =>
        reject(new Error('Failed to load reCAPTCHA script'));
      document.head.appendChild(script);
    });
  };

  const removeReCaptchaScript = (siteKey: string) => {
    const script = document.querySelector(
      `script[src="https://www.google.com/recaptcha/enterprise.js?render=${siteKey}"]`
    );
    if (script) {
      document.head.removeChild(script);
    }
  };
  useEffect(() => {
    const loadScript = async () => {
      try {
        await loadReCaptchaScript(environment?.recaptchaSiteKey);
      } catch (error) {
        RemoteErrorLog(error, 'SignUp.tsx');
      }
    };

    loadScript();

    return () => {
      removeReCaptchaScript(environment?.recaptchaSiteKey);
    };
  }, []);
  return (
    <>
      {' '}
      <Helmet>
        <title>
          Sign Up to Learn & Play Spelling Games For Kids - Spelling Hero
        </title>
        <meta
          name="description"
          content="Let's play our fun spelling games app for kids to practice spelling words and learn at a variety of levels. Simply sign up for free to get started!"
        />
        <link rel="canonical" href="https://spellinghero.app/signup"></link>
      </Helmet>
      <header>
        {' '}
        <Navbar currentPage="signup" />
      </header>
      <main className="main-container">
        <AlertPopup
          open={alertOpen}
          onClose={handleClose}
          severity={alertSeverity}
          message={alertMessage}
        />
        {/* <CookiesModal
          email={email}
          firstName={firstName}
          lastName={lastName}
          age={age}
          firebaseUserProvider={firebaseUserProvider}
          firebaseUser={firebaseUser}
          // navigation={navigation}
          isModalVisible={isCookieModalVisible}
          onAccept={hideCookieModal}
          onManage={openCookiePolicyModal}
        /> */}
        {/* <CookiePolicyModal
          email={email}
          firstName={firstName}
          lastName={lastName}
          age={userDob}
          firebaseUserProvider={firebaseUserProvider}
          firebaseUser={firebaseUser}
          // navigation={navigation}
          isModalVisible={isCookiePolicyModalVisible}
          onAccept={hideCookieModal}
          onSavePreference={onSavePreference}
          handleClose={() => setIsCookiePolicyModalVisible(false)}
        /> */}
        <CustomModal
          isModalVisible={isModalVisible}
          onPress={hideModal}
          buttonText="Continue"
          modalTitle="Verify your Email ID"
          text="Please check your email to proceed. We've sent a verification link to
          your email."
          icon={true}
        />
        <CustomNoResponseModal
          isModalVisible={isQuestionModalVisible}
          onPress={hideNoQuestionModal}
          buttonText="OK"
          modalTitle="Oops!"
          text={apiErrorMessage}
        />

        <Box sx={{ padding: 0 }}>
          <Grid container spacing={2}>
            <Grid
              item
              lg={5}
              xs={12}
              sm={12}
              sx={{ paddingLeft: 0 }}
              className="sidebar-top-spacing"
            >
              <Sidebar />
            </Grid>
            <Grid item xs={12} sm={12} lg={7} xl={6}>
              {isLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                  }}
                >
                  {' '}
                  <CircularProgress
                    size={56}
                    sx={{
                      color: 'primary.main',
                    }}
                  />{' '}
                </Box>
              ) : (
                <section>
                  <Box
                    className="form-container"
                    sx={{ paddingLeft: { xl: 5, lg: 3 } }}
                  >
                    <Typography variant="h2" gutterBottom>
                      Sign up
                    </Typography>
                    <form>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className="mb-3 signup-flex-basis-remove"
                      >
                        <Grid item md={4} sx={{ flexBasis: '33%' }}>
                          <Typography
                            variant="body1"
                            component="label"
                            className="font-600"
                          >
                            Your birth date*
                          </Typography>
                        </Grid>
                        <Grid item md={8} sx={{ flexBasis: '67%' }}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Select
                                placeholder="Month"
                                options={monthsOfYear}
                                value={month}
                                onChange={(selectedMonth) => {
                                  setMonthValue(selectedMonth?.value);
                                  handleDateChange(selectedMonth, day, year);
                                }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Select
                                placeholder="Day"
                                options={daysOfMonth}
                                value={day}
                                onChange={(selectedDay) => {
                                  setDayValue(selectedDay?.value);
                                  handleDateChange(month, selectedDay, year);
                                }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Select
                                placeholder="Year"
                                options={calenderYears}
                                value={year}
                                onChange={(selectedYear) => {
                                  setYearValue(selectedYear?.value);
                                  handleDateChange(month, day, selectedYear);
                                }}
                              />
                            </Grid>
                          </Grid>
                          {validateDate === true && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid date
                            </div>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className="mb-3 signup-flex-basis-remove"
                      >
                        <Grid item md={4} sx={{ flexBasis: '33%' }}>
                          <Typography
                            variant="body1"
                            className="font-600"
                            component="label"
                          >
                            {age > 13.002
                              ? 'Your email*'
                              : 'Your parent email*'}
                          </Typography>
                        </Grid>
                        <Grid item md={8} sx={{ flexBasis: '67%' }}>
                          <TextField
                            fullWidth
                            type="email"
                            placeholder="example@email.com"
                            variant="outlined"
                            value={email}
                            onChange={(e) => validateEmail(e.target.value)}
                          />
                          {errorStatus && (
                            <div style={{ color: 'red' }}>{errorStatus}</div>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className="mb-3 signup-flex-basis-remove"
                      >
                        <Grid item md={4} sx={{ flexBasis: '33%' }}>
                          <Typography
                            variant="body1"
                            className="font-600"
                            component="label"
                          >
                            First name*
                          </Typography>
                        </Grid>
                        <Grid item md={8} sx={{ flexBasis: '67%' }}>
                          <TextField
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => handleFirstName(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className="mb-3 signup-flex-basis-remove"
                      >
                        <Grid item md={4} sx={{ flexBasis: '33%' }}>
                          <Typography
                            variant="body1"
                            className="font-600"
                            component="label"
                          >
                            Last name*
                          </Typography>
                        </Grid>
                        <Grid item md={8} sx={{ flexBasis: '67%' }}>
                          <TextField
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => handleLastName(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className="mb-3 signup-flex-basis-remove"
                      >
                        <Grid item md={4} sx={{ flexBasis: '33%' }}>
                          <Typography
                            variant="body1"
                            className="font-600"
                            component="label"
                          >
                            Create a password*
                            <Typography variant="caption" component="p">
                              {' '}
                              {age < 13.002 &&
                                '(We will email this to your parent)'}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={8} sx={{ flexBasis: '67%' }}>
                          <TextField
                            fullWidth
                            type="password"
                            placeholder="Please enter at least 6 characters."
                            variant="outlined"
                            value={createPassword}
                            onChange={(e) => {
                              setCreatePassword(e.target.value);
                              validatePassword(e.target.value);
                            }}
                          />
                          {errorMessage ? (
                            <div style={{ color: 'red' }}>{errorMessage}</div>
                          ) : null}
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item md={10} sx={{ margin: 'auto' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => setIsChecked(e.target.checked)}
                                name=""
                                checked={isChecked}
                              />
                            }
                            sx={{ paddingBottom: '40px' }}
                            label={
                              <Typography variant="body2">
                                By clicking here, I agree to the{' '}
                                <Link to="#">Privacy Policy</Link> and{' '}
                                <Link to="#">Terms & Conditions</Link>.
                              </Typography>
                            }
                          />
                          {/* <Link to="/email-verify"> */}
                          <Button
                            variant="contained"
                            disabled={
                              !(
                                AgeEnable &&
                                emailEnable &&
                                passEnable &&
                                firstEnable &&
                                lastEnable &&
                                isChecked &&
                                !validateDate
                              )
                            }
                            onClick={() => handleSignUpClick('')}
                            className="btn btn-primary btn-member"
                            sx={{ textTransform: 'none' }}
                            fullWidth
                            startIcon={
                              <img
                                src={mailicon}
                                alt="mail icon"
                                className="icon-offset"
                              />
                            }
                          >
                            Sign up
                          </Button>

                          {(age === 0 || age > 13.002) && (
                            <>
                              <Box className="seperator">
                                <span>OR</span>
                              </Box>
                              <Button
                                variant="outlined"
                                className="btn btn-member btn-outline-secondary "
                                sx={{ textTransform: 'none' }}
                                onClick={() => handleSignUpClick('google')}
                                fullWidth
                                startIcon={
                                  <img
                                    src={googleicon}
                                    alt="google icon"
                                    className="icon-offset"
                                  />
                                }
                              >
                                Sign up with Google
                              </Button>
                            </>
                          )}
                          <Typography
                            variant="body1"
                            sx={{ textTransform: 'none' }}
                            className="text-center mt-3 font-600"
                          >
                            Already have an account?{' '}
                            <Link to="/login">Login</Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </section>
              )}
            </Grid>
          </Grid>
        </Box>
      </main>
      <Footer />
    </>
  );
}
